import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Layout from "../components/Layout";
import { Link, navigate } from "gatsby";
import PartnerLogo from "../../svg/partner-sign-up-logo.svg";
import { useForm } from "react-hook-form";
import useIpGeoData from "../hooks/useIpGeoData";
import useWindowSize from "../hooks/useWindowSize";
import axios from "axios";
import tccNotification from "../utils/tccNotification";
import { Notification } from "antd";

const PartnersContactUs = () => {
  const { register, handleSubmit, errors } = useForm();
  const windowSize = useWindowSize();
  const ipGeoData = useIpGeoData();

  const [serverState, setServerState] = useState({ submitting: false, status: null });

  const displayNotificationUsing = (notificationArguments) => {
    Notification.open(notificationArguments);
  };

  const handleServerResponse = (ok, msg, form) => {
    const notificationArguments = ok
      ? tccNotification.buildSuccessNotificationArgsFrom("Thank You", "Your details have been received & one of our Partner Managers will be in touch ASAP.")
      : tccNotification.buildFailedNotificationArgsFrom("There was an error", "Thank you for trying to submit your details. It seems there was a problem that was likely our fault. Please try again.");

    displayNotificationUsing(notificationArguments);

    setServerState({ submitting: false, status: { ok, msg } });
  };

  const onSubmit = (data, e) => {
    const formData = new FormData();

    for (var key in data) {
      formData.append(key, data[key]);
    }

    setServerState({ submitting: true });

    axios({
      method: "post",
      url: "https://getform.io/f/6689c4ea-3538-4e79-ad58-159a1d1a8d64",
      data: formData,
    })
      .then((r) => {
        e.target.reset();
        navigate(`/`, { replace: true });

        handleServerResponse(true, "Done", e);
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error, e);
      });
  };

  const fieldLabelStyle = windowSize.isLargeScreenView ? "w-full text-lg" : "w-full text-base";
  const errorsStyle = "inline-block text-xs italic text-red-500";
  const fullWidthTextInputStyle = "w-full border border-gray-200 p-1 bg-white outline-none";
  const halfWidthTextInputStyle = "w-6/12 border border-gray-200 p-1";
  const requiredFieldValidationMessage = "Please complete";
  const pageTitle = "Hazte socio de comparación.com";
  const pageDescription = "Regístrese para convertirse en socio de comparación.com y promocione su marca";

  return (
    <Layout showNavbar={false} pageTitle={pageTitle} pageDescription={pageDescription} pageImagePath="/partnercontactpage.jpg">
      <form onSubmit={handleSubmit(onSubmit)}>
        <section className={`w-full ${windowSize.isLargeScreenView ? `bg-gradient-to-b from-white to-blue-100` : ``}`}>
          {/* Navbar section */}
          <div className="container mx-auto">
            <div className={`flex flex-wrap ${windowSize.isLargeScreenView ? `pb-64` : `pb-0`}  overflow-hidden`}>
              <div className="w-full overflow-hidden">
                <header>
                  <Navbar />
                </header>
              </div>
            </div>
          </div>
        </section>
        <section className={`container mx-auto ${windowSize.isLargeScreenView ? `-mt-64` : `-mt-12`} font-body`}>
          {/* top form section */}
          <div className="flex flex-wrap mt-24 overflow-hidden bg-gradient-to-b from-white to-blue-100">
            {/* section left fill */}
            <div className="w-1/12 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/12 xl:w-1/12"></div>

            {/* section content */}
            <div className="w-10/12 overflow-hidden text-gray-600 sm:w-1/3 md:w-1/3 lg:w-10/12 xl:w-10/12">
              {/* section Heading */}
              <div className="mb-4">
                <div className="pl-1">
                  <span className="inline-flex h-full">
                    <PartnerLogo className="h-6 align-middle" />
                  </span>
                  <span className="inline-flex">
                    <h1 className="text-3xl">Partners</h1>
                  </span>
                </div>
                <p className="text-sm text-gray-300">Thank you for your interest. Please fill your contact details below.</p>
              </div>
              {/* section form*/}
              <div className="flex flex-wrap overflow-hidden">
                {/* form left fill */}
                <div className="w-1/12 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/12 xl:w-1/12"></div>
                {/* form content */}
                <div className={`w-10/12 ${windowSize.isLargeScreenView ? `px-4` : ``} pb-8 overflow-hidden text-gray-600 sm:w-1/3 md:w-1/3 lg:w-10/12 xl:w-10/12`}>
                  <div className="py-2">
                    <label htmlFor="companyName" className={fieldLabelStyle}>
                      Company Name <span className={`${errors.companyName ? `text-red-500` : `text-gray-200 `} align-middle`}>*</span>
                      {errors.companyName && <p className={errorsStyle}>{requiredFieldValidationMessage}</p>}
                    </label>
                    <input
                      className={fullWidthTextInputStyle}
                      placeholder="Please add your company name"
                      id="companyName"
                      name="companyName"
                      aria-labelledby="companyName"
                      ref={register({ required: true })}
                    />
                  </div>
                  <div className="py-2">
                    <label htmlFor="companyWebsite" className={fieldLabelStyle}>
                      Website <span className={`${errors.companyWebsite ? `text-red-500` : `text-gray-200 `} align-middle `}>*</span>
                      {errors.companyWebsite && <p className={errorsStyle}>{requiredFieldValidationMessage}</p>}
                    </label>
                    <input
                      className={fullWidthTextInputStyle}
                      id="companyWebsite"
                      aria-labelledby="companyWebsite"
                      placeholder="www.example.com"
                      name="companyWebsite"
                      ref={register({ required: true })}
                    />
                  </div>
                  <div className="py-2">
                    <label htmlFor="companyEmailAddress" className={fieldLabelStyle}>
                      Email <span className={`${errors.companyEmailAddress ? `text-red-500` : `text-gray-200 `} align-middle`}>*</span>
                      {errors.companyEmailAddress && <p className={errorsStyle}>{requiredFieldValidationMessage}</p>}
                    </label>
                    <input
                      className={fullWidthTextInputStyle}
                      aria-labelledby="companyEmailAddress"
                      placeholder="partner-manager@example.com"
                      name="companyEmailAddress"
                      id="companyEmailAddress"
                      ref={register({ required: true })}
                    />
                  </div>
                  <div className="py-2">
                    <label htmlFor="companyPhoneNumber" className={fieldLabelStyle}>
                      Phone Number <span className={`${errors.companyPhoneNumber ? `text-red-500` : `text-gray-200 `} align-middle `}>*</span>
                      {errors.companyPhoneNumber && <p className={errorsStyle}>{requiredFieldValidationMessage}</p>}
                    </label>
                    <input
                      className={fullWidthTextInputStyle}
                      aria-labelledby="companyPhoneNumber"
                      placeholder="Please enter a phone number"
                      name="companyPhoneNumber"
                      id="companyPhoneNumber"
                      ref={register({ required: true })}
                    />
                  </div>
                  <div>
                    <div className="inline-block w-6/12 py-2 pr-2">
                      <label htmlFor="firstName" className={fieldLabelStyle}>
                        First Name <span className={`${errors.firstName ? `text-red-500` : `text-gray-200 `} align-middle `}>*</span>
                        {errors.firstName && <p className={errorsStyle}>{requiredFieldValidationMessage}</p>}
                      </label>
                      <input className={fullWidthTextInputStyle} aria-labelledby="firstName" id="firstName" name="firstName" placeholder="Jane" ref={register({ required: true })} />
                    </div>
                    <div className="inline-block w-6/12 py-2 pl-2">
                      <label htmlFor="lastName" className={fieldLabelStyle}>
                        Last Name <span className={`${errors.firstName ? `text-red-500` : `text-gray-200 `} align-middle`}>*</span>
                        {errors.lastName && <p className={errorsStyle}>{requiredFieldValidationMessage}</p>}
                      </label>
                      <input className={fullWidthTextInputStyle} aria-labelledby="lastName" id="lastName" name="lastName" placeholder="Smith" ref={register({ required: true })} />
                    </div>
                  </div>
                </div>
                {/* form right fill */}
                <div className="w-1/12 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/12 xl:w-1/12"></div>
              </div>
            </div>

            {/* section right fill */}
            <div className="w-1/12 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/12 xl:w-1/12"></div>
          </div>
        </section>

        <section className="container mx-auto my-4 font-body">
          {/* Banner section */}
          <div className="flex flex-wrap py-4 overflow-hidden text-white bg-gray-500 rounded">
            {/* section left fill */}
            <div className="w-1/12 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/12 xl:w-1/12"></div>

            {/* section content */}
            <div className="w-10/12 overflow-hidden text-white sm:w-1/3 md:w-1/3 lg:w-10/12 xl:w-10/12">
              <div className="">
                <span className="w-6/12 lg:inline-block">
                  <p className="text-left lg:text-right">How did you hear about The Comparison Company?</p>
                </span>
                <span className="inline-block w-6/12 pl-2 text-gray-900">
                  <select className="w-64 px-3 py-2 bg-white border rounded outline-none " name="source" ref={register()}>
                    <option value="Advertisements">Advertisements</option>
                    <option value="Adwords">Adwords</option>
                    <option value="Email">Email</option>
                    <option value="Event">Event</option>
                    <option value="Website">Website</option>
                    <option value="Instagram">Instagram</option>
                    <option value="LinedIn">LinedIn</option>
                    <option value="Facebook">Facebook</option>
                    <option value="Twitter">Twitter</option>
                    <option value="Youtube">Youtube</option>
                    <option value="Web Research">Web Research</option>
                    <option value="Existing TCC Partner">Existing TCC Partner</option>
                    <option value="Existing TCC Customer">Existing TCC Customer</option>
                    <option value="The TCC Website" selected="selected">
                      The TCC Website
                    </option>
                    <option value="Other">Other</option>
                  </select>
                </span>
              </div>
            </div>

            {/* section right fill */}
            <div className="w-1/12 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/12 xl:w-1/12"></div>
          </div>
        </section>
        <section className="container mx-auto my-4 bg-blue-100 font-body">
          {/* address form */}
          <div className="flex flex-wrap overflow-hidden">
            {/* left fill */}
            <div className="w-1/12 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/12 xl:w-2/12"></div>

            {/* content */}
            <div className="w-10/12 px-4 overflow-hidden text-gray-600 sm:w-1/3 md:w-1/3 lg:w-10/12 xl:w-8/12">
              {/* Heading */}
              <div className="pt-8 ">
                <h3 className="p-0 text-xl lg:text-2xl">Business Address</h3>
              </div>

              {/* bottom form */}
              <div className="pb-8">
                <div className="py-2">
                  <label htmlFor="address1" className={fieldLabelStyle}>
                    Address Line 1 <span className={`${errors.address1 ? `text-red-500` : `text-gray-200 `} align-middle `}>*</span>
                    {errors.address1 && <p className={errorsStyle}>{requiredFieldValidationMessage}</p>}
                  </label>
                  <input
                    className={fullWidthTextInputStyle}
                    aria-labelledby="address1"
                    id="address1"
                    placeholder="Please enter the first line of your address"
                    name="address1"
                    ref={register({ required: true })}
                  />
                </div>
                <div className="py-2">
                  <label htmlFor="address2" className={fieldLabelStyle}>
                    Address Line 2
                  </label>
                  <input
                    className={fullWidthTextInputStyle}
                    aria-labelledby="address2"
                    id="address2"
                    placeholder="Please enter the second line of your address (optional)"
                    name="address2"
                    ref={register()}
                  />
                </div>
                <div>
                  <div className="inline-block w-6/12 py-2 pr-2">
                    <label htmlFor="addressMunicipal" className={fieldLabelStyle}>
                      City/Town <span className={`${errors.addressMunicipal ? `text-red-500` : `text-gray-200 `} align-middle `}>*</span>
                      {errors.addressMunicipal && <p className={errorsStyle}>{requiredFieldValidationMessage}</p>}
                    </label>
                    <input
                      defaultValue={ipGeoData.city}
                      className={fullWidthTextInputStyle}
                      id="addressMunicipal"
                      aria-labelledby="addressMunicipal"
                      name="addressMunicipal"
                      placeholder="Please enter your city/town"
                      ref={register({ required: true })}
                    />
                  </div>
                  <div className="inline-block w-6/12 py-2 pl-2">
                    <label htmlFor="addressState" className={fieldLabelStyle}>
                      State <span className={`${errors.addressState ? `text-red-500` : `text-gray-200 `} align-middle`}>*</span>
                      {errors.addressState && <p className={errorsStyle}>{requiredFieldValidationMessage}</p>}
                    </label>
                    <input
                      defaultValue={ipGeoData.regionName}
                      className={fullWidthTextInputStyle}
                      id="addressState"
                      aria-labelledby="addressState"
                      name="addressState"
                      placeholder="Please enter your state"
                      ref={register({ required: true })}
                    />
                  </div>
                </div>
                <div className="py-2">
                  <label htmlFor="addressZipCode" className={fieldLabelStyle}>
                    Zip Code <span className={`${errors.addressZipCode ? `text-red-500` : `text-gray-200 `} align-middle `}>*</span>
                    {errors.addressZipCode && <p className={errorsStyle}>{requiredFieldValidationMessage}</p>}
                  </label>
                  <div className="pr-4">
                    <input
                      defaultValue={ipGeoData.postalCode}
                      className={halfWidthTextInputStyle}
                      aria-labelledby="addressZipCode"
                      id="addressZipCode"
                      placeholder="Please enter a valid zip code"
                      name="addressZipCode"
                      ref={register({ required: true })}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* right fill */}
            <div className="w-1/12 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/12 xl:w-2/12"></div>
          </div>
        </section>
        <section className="container mx-auto mb-16 bg-blue-100 font-body">
          {/* message form */}
          <div className="flex flex-wrap overflow-hidden ">
            {/* left fill */}
            <div className="w-1/12 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/12 xl:w-2/12"></div>

            {/* content */}
            <div className="w-10/12 px-4 pt-8 overflow-hidden text-gray-600 sm:w-1/3 md:w-1/3 lg:w-10/12 xl:w-8/12">
              {/* Heading */}
              <div className="mb-4">
                <label htmlFor="message" className="w-full">
                  <h3 className="p-0 text-xl lg:text-2xl">
                    Message <span className={`${errors.message ? `text-red-500` : `text-gray-200 `} align-middle `}>*</span>
                    {errors.message && <p className={errorsStyle}>{requiredFieldValidationMessage}</p>}
                  </h3>
                </label>
              </div>

              {/* bottom form */}
              <div className="pb-8">
                <div className="py-2">
                  <textarea
                    type="textarea"
                    aria-labelledby="message"
                    id="message"
                    className={fullWidthTextInputStyle}
                    placeholder="Please type your message here"
                    name="message"
                    rows="8"
                    ref={register({ required: true })}
                  />
                </div>
              </div>
              <div className="w-full pb-8 text-center">
                <p className="pb-6 text-sm text-gray-300">
                  I agree to the{" "}
                  <Link to="/terms-and-conditions" title="The Comparison Company Terms Of Service">
                    Terms Of Service
                  </Link>{" "}
                  and{" "}
                  <Link to="/privacy-policy" title="The Comparison Company Privacy Policy">
                    Privacy Policy
                  </Link>
                  .
                </p>
                <input
                  type="submit"
                  aria-label="submit"
                  className="w-4/12 px-4 py-2 text-gray-500 bg-yellow-500 border border-yellow-500 hover:bg-gray-500 hover:border-gray-500 hover:text-yellow-500 focus:outline-none"
                  disabled={serverState.submitting}
                />
              </div>
            </div>

            {/* right fill */}
            <div className="w-1/12 overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/12 xl:w-2/12"></div>
          </div>
        </section>
      </form>
    </Layout>
  );
};

export default PartnersContactUs;
